import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

import {
  news,
  newsItem,
  articleItem,
  articleDate,
  articleImage,
} from "../styles/news.module.css"

export default function Photos({ data }) {
  const articles = data.allContentfulProject.edges.map(edge => edge.node)

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

  function formatDate(date) {
    const _date = new Date(date)
    const year = _date.getFullYear()
    const day = _date.getDate()

    const monthIndex = _date.getMonth()
    const monthName = months[monthIndex]

    const dayIndex = _date.getDay()
    const dayName = days[dayIndex]

    return `${dayName}, ${day} ${monthName} ${year}`
  }

  function getDescription(article) {
    return article.description
      ? article.description.childMarkdownRemark.html
      : ""
  }

  return (
    <Layout>
      <section className={`${news} container`}>
        {articles.map(article => {
          return (
            <section className={newsItem} key={article.id}>
              <GatsbyImage
                className={articleImage}
                image={getImage(article.thumbnail)}
                alt={article.title}
              />
              <div className={articleItem}>
                <span className={articleDate}>{formatDate(article.date)}</span>
                <article
                  dangerouslySetInnerHTML={{ __html: getDescription(article) }}
                />
              </div>
            </section>
          )
        })}
      </section>
    </Layout>
  )
}

// Export page query
export const query = graphql`
  query NewsPage {
    allContentfulProject(
      filter: { context: { eq: "news" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          date
          thumbnail {
            gatsbyImageData
          }
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
